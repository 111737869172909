@import '../../abstracts/_mixins';
@import '../../abstracts/_variables';
@import '../../components/_button';
@import '../../components/_title';

.blockcms {
    &__selection {
        /* ********************************  DESIGN ******************************** */
        background: url(#{$imagesPath}/home/store.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-origin: padding-box;
        background-clip: border-box;
        box-shadow: inset 0 0 0 1000px #1e0501c7;

        section {
            &.row {
                &:nth-child(1) {
                    padding-top: 10rem;
                }

                &:nth-child(2) {
                    padding-bottom: 10rem;
                }
            }
        }

        .read-more-toggle {
            font-size: 2rem;
            font-size: 1.4rem;
            font-weight: bold;
            text-decoration: underline;
            color: var(--toc-orange);
            background: none;
            border: none;
        }

        .not-show {
            display: none;
        }

        .is-show {
            display: block;
        }

        &__text {
            h1 {
                color: var(--toc-orange);
                font-size: 3.5rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            h2 {
                color: var(--toc-orange);
                font-size: 2rem;
                font-weight: bold;
            }

            p {
                color: var(--toc-white);
            }
        }

        /* ********************************  RESPONSIVE ******************************** */
        @media (max-width: $breakpoint-lg) {
            &__logo {
                display: none !important
            }
        }
    }

    &__researches {
        /* ********************************  DESIGN ******************************** */
        @include doubleTitle();
        justify-content: space-between;
        padding-top: 4rem;
        padding-bottom: 4rem;

        &__store {
            display: flex;
            gap: 2rem;

            &::after {
                display: none;

                @media (min-width: $breakpoint-xxl) {
                    display: block;
                    position: relative;
                    right: 0;
                    content: " ";
                    height: 100%;
                    border-left: 2px solid var(--toc-orange);
                }
            }

            &__image {
                img {
                    object-fit: cover;
                }
            }

            &__content {
                &__action {
                    @include flex-column-justify(space-evenly);
                    height: 100%;

                    .-button {
                        @include button-cta(noug);
                        width: fit-content
                    }
                }
            }
        }

        &__magalogue {
            display: flex;
            gap: 2rem;

            &__image {
                p {
                    margin: 0;
                }

                & img {
                    object-fit: cover;
                }
            }

            &__content {
                &__action {
                    @include flex-column-justify(space-evenly);
                    height: 100%;

                    .-button {
                        @include button-cta(noug);
                        width: fit-content;
                    }
                }
            }
        }

        /* ********************************  RESPONSIVE ******************************** */
        @media (max-width: $breakpoint-xxl) {
            row-gap: 3rem;

            &__store,
            &__magalogue {
                flex-direction: column;
                align-items: center;

                &__content {
                    &__action {
                        align-items: center;
                    }
                }
            }
        }
    }
}

.netreviews-module {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &-title {
        @include title();
    }
}